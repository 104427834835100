import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Typography } from "@material-ui/core"
import Layout from "../components/layout/layout-home"
import TitledContentWrapper from "../components/layout/titled-content-wrapper"
import ReactMarkdown from "react-markdown"
import { makeStyles } from "@material-ui/core/styles"
import RemarkGfmPlugin from "remark-gfm"

const useStyles = makeStyles(theme => ({
  content: {
    paddingBottom: "50px",
    maxWidth: "1000px",
    margin: "auto",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
}))

const FaqPage = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    {
      strapi {
        faqPage {
          FAQContent
          FAQTitle
        }
      }
    }
  `)

  return (
    <Layout>
      <TitledContentWrapper
        title={data.strapi.faqPage.FAQTitle}
        maxWidth="1000px"
      >
        <div className={classes.content}>
          <ReactMarkdown
            remarkPlugins={[RemarkGfmPlugin]}
            children={data.strapi.faqPage.FAQContent}
            components={{
              h1: ({ node, ...props }) => (
                <Typography
                  variant="h1"
                  style={{
                    marginTop: "24px",
                    marginBottom: "8px",
                  }}
                  {...props}
                />
              ),
              h2: ({ node, ...props }) => (
                <Typography
                  variant="h2"
                  style={{
                    marginTop: "24px",
                    marginBottom: "8px",
                  }}
                  {...props}
                />
              ),
              h3: ({ node, ...props }) => (
                <Typography
                  variant="h3"
                  style={{
                    color: "#F26522",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                  {...props}
                />
              ),
              p: ({ node, ...props }) => (
                <Typography variant="body1" {...props} />
              ),
              a: ({ node, ...props }) => (
                <a
                  style={{ color: "#F26522", lineBreak: "anywhere" }}
                  {...props}
                />
              ),
            }}
          />
        </div>
      </TitledContentWrapper>
    </Layout>
  )
}

export default FaqPage
